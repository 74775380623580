<template>
  <div class="create-contract-modal">
    <aForm :form="form" @submit="handleSubmit">
      <aRow :gutter="20">
        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Empresa </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              @change="getCompanyBranchesOnChange"
              v-decorator="[
                `company_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              :disabled="companiesList.length > 0 ? false : true"
            >
              <a-select-option
                v-for="(item, index) of companiesList"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.id }} - {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </aFormItem>

          <aFormItem class="none">
            <a-input v-decorator="[`company`]" placeholder="Empresa"> </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Filial </label>

            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              :disabled="companyBranchesList.length == 0"
              v-decorator="[
                `company_branch_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              @change="onCompanyBranchesChange"
            >
              <a-select-option
                v-for="(item, index) of companyBranchesList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </aFormItem>

          <aFormItem class="none">
            <a-input v-decorator="[`company_branch`]" placeholder="Filial">
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Origem do cliente </label>

            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `customer_origin`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of customerOriginList"
                :key="index"
                :value="item.name"
                :txt="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </aFormItem>

          <aFormItem class="none">
            <a-input v-decorator="[`company_branch`]" placeholder="Filial">
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Cliente (opcional) </label>

            <a-auto-complete
              :data-source="
                customers.list.map(
                  ({
                    first_name,
                    last_name,
                    person_type,
                    trading_name,
                    id,
                  }) => ({
                    value: id,
                    text: `${id} - ${
                      person_type == 'Pessoa Física'
                        ? first_name.toUpperCase() +
                          ' ' +
                          last_name.toUpperCase()
                        : trading_name.toUpperCase()
                    }`,
                  })
                )
              "
              style="width: 100%"
              placeholder="ID, Nome, Nome Fantasia..."
              v-model="customers.filters.customerName"
              @search="searchCustomers"
              @select="onCustomerSelected"
            >
              <a-input v-decorator="[`customer_id`]" style="border-radius: 0" />
            </a-auto-complete>
          </aFormItem>
        </aCol>

        <aCol
          v-if="form.getFieldValue('customer_origin') === 'Indicação'"
          :span="15"
        >
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'">
              Cliente que fez a indicação (opcional)
            </label>

            <a-auto-complete
              style="width: 79%"
              placeholder="ID, Nome, Nome Fantasia..."
              v-model="customerWhoReferred"
              @search="searchCustomersWhoReferred"
              @select="onCustomerWhoReferredSelected"
            >
              <template slot="dataSource">
                <a-select-option
                  v-for="item in customersWhoReferredList.map(
                    ({
                      first_name,
                      last_name,
                      person_type,
                      trading_name,
                      id,
                    }) => ({
                      value: id,
                      text: `${id} - ${
                        person_type == 'Pessoa Física'
                          ? first_name.toUpperCase() +
                            ' ' +
                            last_name.toUpperCase()
                          : trading_name.toUpperCase()
                      }`,
                    })
                  )"
                  :key="item.value"
                  :title="item.text"
                  :txt="item.text"
                  >{{ item.text }}</a-select-option
                >
              </template>
              <a-input
                v-decorator="[`customer_who_referred_id`]"
                style="border-radius: 0"
              />
            </a-auto-complete>
            <a-button
              class="ml-10"
              type="primary"
              @click="openCreateCustomer = true"
              shape="circle"
              size="small"
              icon="plus"
            />
          </aFormItem>

          <aFormItem class="none">
            <aInput
              class="travel-input"
              v-decorator="['customer_who_referred_name']"
            />
          </aFormItem>
        </aCol>

        <aCol :span="24">
          <aFormItem class="mb-0">
            <a-radio-group
              class="package-type"
              v-decorator="[
                'package_type',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione',
                    },
                  ],
                },
              ]"
            >
              <a-radio
                v-for="(type, index) in packageTypes"
                :key="index"
                :value="type.value"
                :disabled="type.disabled"
              >
                <span class="label">
                  {{ type.label }}
                </span>

                <div class="description">
                  {{ type.description }}
                </div>
              </a-radio>
            </a-radio-group>
          </aFormItem>
        </aCol>
      </aRow>

      <aRow class="mt-20" :gutter="20">
        <aCol :span="12" class="input">
          <aFormItem class="travel-input-outer mb-0">
            <label for :class="'filled'">ID Infotera</label>
            <a-input
              type="number"
              class="travel-input"
              placeholder="Insira o ID (opcional)"
              v-model="idInfotera"
              :disabled="infoteraLoading"
              @keyup.enter="getContracts"
            >
              <a-icon slot="prefix" type="field-svg" />
              <a-icon
                class="cgreen"
                v-if="!infoteraLoading"
                slot="suffix"
                @click="getContracts"
                type="vertical-align-bottom"
              />
              <a-icon v-if="infoteraLoading" slot="suffix" type="loading" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol class="a-right" :span="12" :offset="0">
          <a-button
            class="travel-btn green"
            :disabled="disableButton"
            :loading="loadingNewContract"
            html-type="submit"
          >
            <a-icon type="edit-svg" style="zoom: 0.028" />
            Novo Contrato
          </a-button>
        </aCol>
      </aRow>
    </aForm>

    <aModal
      v-model="openCreateCustomer"
      :footer="false"
      @cancel="openCreateCustomer = false"
      :width="340"
    >
      <template slot="title">
        <aIcon type="user" class="mr-5 cprimary" /> CADASTRAR CLIENTE
      </template>

      <CustomerBasicForm
        v-if="openCreateCustomer"
        @onCreateCustomer="onCreateCustomer"
      />
    </aModal>
  </div>
</template>

<script>
import _ from "lodash";

import formatInfoteraToHaya from "../mixins/formatInfoteraToHaya";
import formatThings from "@/mixins/general/formatThings";
import customerMixins from "@/components/customers/mixins/customerMixins";
import CustomerBasicForm from "@/components/customers/forms/CustomerBasicForm.vue";

export default {
  components: { CustomerBasicForm },
  name: "NewContractModal",
  mixins: [formatInfoteraToHaya, customerMixins, formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      disableButton: false,
      loadingNewContract: false,
      openCreateCustomer: false,
      customerWhoReferred: "",

      customersWhoReferredList: [],
      companiesList: [],
      companyBranchesList: [],
      customerOriginList: [],
      packageTypes: [
        {
          label: "Pacote Completo Terceiros",
          value: "Pacote Completo Terceiros",
          description: "Venda de Operadoras Terceiras, Valor único.",
        },
        {
          label: "Selecionar Serviços",
          value: "Serviços",
          description: "Vendas Próprias, Valor Separado por produtos.",
        },
        {
          label: "Reserva Online",
          value: "Reserva Online",
          description: "",
          disabled: false,
        },
      ],
    };
  }, //getContracts
  beforeMount() {
    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    this.$http
      .get(`/category-entry/list?page=1&category_id=24&per_page=300`)
      .then(({ data }) => {
        this.customerOriginList = data.data;
      });
  },
  methods: {
    searchCustomers: _.debounce(function () {
      setTimeout(() => {
        this.getCustomers();
      }, 1000);
    }, 600),

    onCreateCustomer(data) {
      this.form.setFieldsValue({
        [`customer_who_referred_id`]: data.id,
        [`customer_who_referred_name`]: `${data.id} - ${data.first_name} ${data.last_name}`,
      });

      this.customerWhoReferred = `${data.id} - ${data.first_name} ${data.last_name}`;
      this.openCreateCustomer = false;
    },
    getContracts() {
      this.$http
        .get(
          `/contract-v2/list?page=1&per_page=10&id_infotera=${this.idInfotera}&order=desc&order-by=status`
        )
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.$message.warning(
              `O ID ${this.idInfotera} já foi importado no contrato ID ${data.data[0].id}.`
            );
          } else {
            this.getInfotera();
          }
        })
        .catch(() => {
          this.getInfotera();
        });
    },
    onCustomerSelected(customerId) {
      this.form.setFieldsValue({
        [`customer_id`]: customerId,
      });
    },
    searchCustomersWhoReferred: _.debounce(function () {
      setTimeout(() => {
        this.$http
          .get(
            `/customer/list?page=1&per_page=150&customer_name=${this.customerWhoReferred}&have_cpf=true&order=desc&order-by=created`
          )
          .then(({ data }) => {
            this.customersWhoReferredList = data.data;
          });
      }, 1000);
    }, 600),
    onCustomerWhoReferredSelected(customerId, obj) {
      this.form.setFieldsValue({
        [`customer_who_referred_id`]: customerId,
        [`customer_who_referred_name`]: `${obj.data.attrs.txt}`,
      });
    },
    onCompanyBranchesChange(id) {
      let companyBranch = this.companyBranchesList.filter((c) => {
        return c.id == id;
      });

      this.form.setFieldsValue({
        [`company_branch`]: companyBranch[0].name,
      });
    },
    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
        company_branch: undefined,
      });

      let company = this.companiesList.filter((c) => {
        return c.id == id;
      });

      this.form.setFieldsValue({
        [`company`]: company[0].trading_name,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}&show_in_contract=1`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        });
    },

    handleSubmit(e) {
      e.preventDefault();

      this.$store.state.createContract = false;
      this.form.validateFields((err, values) => {
        console.log(values);
        values.id_infotera = this.idInfotera;
        values.user = this.$store.state.userData.id;
        values.status = "new";
        if (!err) {
          this.loadingNewContract = true;
          this.new({ ...values, ...this.infoteraFormattedData });
        }
      });
    },
    new(contractData) {
      delete contractData["contract_documents"];
      if (contractData.customer_id) {
        contractData.status = "edit";

        this.$http
          .post("/contract-v2/create", {
            ...contractData,
            payment_methods: JSON.stringify([]),
            contract_documents: JSON.stringify([]),
            ...this.infoteraFormattedData,
          })
          .then(({ data }) => {
            this.$message.success(data.message);
            this.$router.push(`edit/${data.id}`);
            this.loadingNewContract = false;
            this.$emit("closeNewContractModal");
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.loadingNewContract = false;
          });
      } else {
        this.$store.state.newContract = contractData;
        this.$store.state.newContract.meta = {};
        this.$store.state.newContract.meta.payment_methods = JSON.stringify([]);
        this.$store.state.newContract.meta.contract_documents = JSON.stringify(
          []
        );
        this.$store.state.newContract.meta = {
          ...this.$store.state.newContract.meta,
          ...this.infoteraFormattedData,
        };

        this.$router.push("new");
        this.$emit("closeNewContractModal");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.create-contract-modal
  .package-type
    position: relative
    .ant-radio-wrapper
      color: #444
      font-weight: 600
      font-size: 12px
      display: block  !important
      .description
        font-weight: 400
        color: #aaa
  .anticon-vertical-align-bottom
    font-size: 23px
    right: -12px
    position: relative
    top: 1px
  .ant-form-item-children
    > label
      position: absolute !important
      z-index: 1
      font-size: 10px !important
      font-weight: 500
      color: #aaa
      transition: .3s
      opacity: 1 !important
      top: -15px !important
      left: 0
      line-height: 1
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      white-space: nowrap
</style>

import { format, parse } from "date-fns";

export default {
  data() {
    return {
      contract: {
        loading: false,
        list: [],
        filters: {
          loadingResults: false,
          ticketed: false,
          paymentMethods: {
            list: [
              {
                label: "Cartão de Crédito",
                value: "credit-card",
              },
              {
                label: "Boleto",
                value: "billing-ticket",
              },
              {
                label: "Transferência Bancária",
                value: "bank-transfer",
              },
              {
                label: "Carta de Crédito",
                value: "payment-card",
              },
              {
                label: "Personalizado",
                value: "custom-payment",
              },
              {
                label: "Link de Pagamento",
                value: "payment-link",
              },
            ],
            selected: [],
          },
          companyBranches: {
            list: [
              {
                label: "Viajar Resorts",
                value: "Viajar Resorts",
              },
              {
                label: "Voe Simples",
                value: "Voe Simples",
              },
              {
                label: "Credimilhas",
                value: "Credimilhas",
              },
            ],
            selected: [],
          },
          companies: {
            list: [
              {
                label: "Viajar Resorts",
                value: "Viajar Resorts",
              },
              {
                label: "Voe Simples",
                value: "Voe Simples",
              },
              {
                label: "Credimilhas",
                value: "Credimilhas",
              },
            ],
            selected: [],
          },
          personTypes: {
            list: [
              {
                label: "Pessoa Física",
                value: "Pessoa Física",
              },
              {
                label: "Pessoa Jurídica",
                value: "Pessoa Jurídica",
              },
            ],
            selected: [],
          },
          users: {
            list: [],
            selected: [],
          },
          notUsers: {
            list: [],
            selected: [],
          },
          marketing: {
            list: [
              {
                label: "Livelo",
                value: "Livelo",
                disabled: false,
              },
            ],
            selected: [],
          },
          productsCategory: {
            list: [
              {
                label: "Hotéis/Resorts",
                value: "hotel",
              },
              {
                label: "Aéreo",
                value: "flight",
              },
              {
                label: "Serviços",
                value: "service",
              },
            ],
            selected: [],
          },
          saleType: {
            list: [
              {
                label: "Pacote Completo",
                value: "Pacote Completo Terceiros",
              },
              {
                label: "Serviços",
                value: "Serviços",
              },
              {
                label: "Reserva Online",
                value: "Reserva Online",
              },
            ],
            selected: [],
          },
          flightType: {
            list: [
              {
                label: "Milhas",
                value: "Milhas",
              },
              {
                label: "Regular",
                value: "Regular",
              },
            ],
            selected: [],
          },
          products: {
            list: [],
            selected: [],
          },
          clients: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Concluído",
                value: "concluded",
              },
              {
                label: "Em edição",
                value: "edit",
              },
              {
                label: "Cancelado",
                value: "canceled",
              },
            ],
            selected: ["concluded", "edit"],
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          id: "",
          customer: "",
          openModal: false,
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "status",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          totalPages: 0,
        },
      },
      excelFile: {
        header: "Contratos",
        fileName: "Contratos.xls",
        collumns: {
          ID: "id",
          Contrante: {
            field: "customer",
            callback: (customer) => {
              return `${customer.first_name} ${customer.last_name}`;
            },
          },
          Produtos: {
            field: "meta",
            callback: (meta) => {
              return `${this.formatProductsTxt(meta)}`;
            },
          },
          Bilhetado: {
            field: "flight_is_ticketed",
            callback: (flight_is_ticketed) => {
              return `${flight_is_ticketed == 1 ? "Bilhetado" : ""}`;
            },
          },
          Livelo: {
            field: "marketing_types",
            callback: (marketing_types) => {
              let txt = "";
              if (marketing_types.includes("Livelo")) txt = "Livelo";
              return `${txt}`;
            },
          },
          Valor: {
            field: "value",
            callback: (value) => {
              return `${this.formatPrice(value)}`;
            },
          },
          Lucratividade: {
            field: "profit_percentage",
            callback: (profit_percentage) => {
              return `${this.contractProfitTxt(profit_percentage)}`;
            },
          },
          Pagamentos: {
            field: "payment_methods",
            callback: (payment_methods) => {
              let txt = "";
              if (payment_methods != undefined) {
                JSON.parse(payment_methods).forEach((payment) => {
                  if (payment == "credit-card") txt += "Cartão de Crédito,";
                  if (payment == "billing-ticket") txt += "Boleto,";
                  if (payment == "payment-card") txt += "Carta de Crédito,";
                  if (payment == "custom-payment") txt += `Personalizado`;
                  if (payment == "bank-transfer")
                    txt += "Transferência Bancária,";
                  if (payment == "payment-link") txt += "Link de Pagamento,";
                });
              }
              return `${txt}`;
            },
          },
          Empresa: {
            field: "company_branch",
            callback: (company_branch) => {
              return `${company_branch.name}`;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
          "Criação/Lançamento": {
            field: "created",
            callback: (created) => {
              return `${this.formatDateTime(created)}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  methods: {
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatPercentage(percentage) {
      let thePercentage = 0;
      if (percentage) thePercentage = percentage;

      return `${Number(parseFloat(thePercentage).toFixed(2)).toLocaleString(
        "pt-BR"
      )}`;
    },
    contractProfit(contract) {
      let html = `<div class=" f11">0%</div>`,
        percentage = 0;

      percentage = contract.profit_percentage
        ? parseFloat(contract.profit_percentage)
        : 0;

      if (percentage > 11)
        html = `<div class="cgreen f11">${this.formatPercentage(
          percentage
        )}%</div>`;

      if (percentage <= 11 && percentage >= 0)
        html = `<div class=" f11">${this.formatPercentage(percentage)}%</div>`;

      if (percentage < 0)
        html = `<div class="red f11">${this.formatPercentage(
          percentage
        )}%</div>`;

      return html;
    },
    contractProfitTxt(profit) {
      let html = `0%`,
        percentage = 0;

      percentage = profit ? parseFloat(profit) : 0;

      if (percentage > 11) html = `${this.formatPercentage(percentage)}%`;

      if (percentage <= 11 && percentage >= 0)
        html = `${this.formatPercentage(percentage)}%`;

      if (percentage < 0) html = `${this.formatPercentage(percentage)}%`;

      return html;
    },
    formatProductsTxt(meta) {
      let txt = "";
      meta;
      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          p;
          // HOTEL
          if (p == "hotel") {
            if (meta.hotel_rows) {
              JSON.parse(meta.hotel_rows).forEach((row) => {
                if (meta[`hotel_${row.id}_name`] != undefined) {
                  txt += meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`] + "<br>"
                    : `HOTEL ${row.id} <br>`;
                }
              });
            }
          }

          //AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            if (meta.service_rows != undefined) {
              JSON.parse(meta.service_rows).forEach((row) => {
                if (meta[`service_${row.id}_name`] != undefined) {
                  txt += meta[`service_${row.id}_name`];
                }
              });
            }
          }
        });
      }

      return txt.toUpperCase();
    },
    formatPaymentsTxt(meta) {
      let txt = "";
      const paymentMethods =
        meta.payment_methods != undefined
          ? JSON.parse(meta.payment_methods)
          : [];

      if (paymentMethods.length > 0) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") txt += "Cartão de Crédito,";
          if (payment == "billing-ticket") txt += "Boleto,";
          if (payment == "payment-card") txt += "Carta de Crédito,";
          if (payment == "custom-payment") txt += `Personalizado,`;
          if (payment == "bank-transfer") txt += "Transferência Bancária,";
          if (payment == "payment-link") txt += "Link de Pagamento,";
        });
      }
      return txt;
    },

    formatPrice(price) {
      return price
        ? `<span class="ant-tag ant-tag-price">${Number(
            parseFloat(price.replace(",", ".")).toFixed(2)
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}</span>`
        : `---`;
    },
    getContracts(samePage) {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      this.contract.loading = true;

      setTimeout(() => {
        if (!this.$root.isAdmin() && !this.$root.isMilesManager() && !this.$root.isAnalyst()) {
          filters += `&user=${this.$store.state.userData.id}`;
        } else {
          if (this.contract.filters.users.selected.length > 0) {
            filters += `&user=${this.contract.filters.users.selected}`;

            queryParams.set("user", `${this.contract.filters.users.selected}`);
          }
        }

        if (this.contract.filters.customer)
          filters += `&customer_id=${this.contract.filters.customer}`;

        if (this.contract.filters.ticketed) {
          filters += `&is-ticketed=${this.contract.filters.ticketed}`;
          queryParams.set("is-ticketed", this.contract.filters.ticketed);
        }

        if (this.contract.filters.companies.selected.length > 0)
          filters += `&company=${this.contract.filters.companies.selected}`;

        if (this.contract.filters.companyBranches.selected.length > 0)
          filters += `&company_branch_id=${this.contract.filters.companyBranches.selected}`;

        if (!this.contract.filters.id && !this.customers.filters.searchTerm) {
          if (this.contract.filters.status.selected.length > 0) {
            filters += `&status=${this.contract.filters.status.selected}`;
            queryParams.set(
              "status",
              `${this.contract.filters.status.selected}`
            );
          }

          if (this.contract.filters.period.selected.length > 0) {
            filters += `&period=${this.contract.filters.period.selected[0]}|${this.contract.filters.period.selected[1]}`;
            queryParams.set(
              "period",
              `${this.contract.filters.period.selected[0]}|${this.contract.filters.period.selected[1]}`
            );
          }
        } else {
          filters += `&id=${this.contract.filters.id}`;
          queryParams.set("id", `${this.contract.filters.id}`);

          if (this.contract.filters.openModal) {
            queryParams.set("open-modal", `${this.contract.filters.openModal}`);

            this.viewContractDetails(this.contract.filters.id);
          }
        }

        if (this.contract.filters.paymentMethods.selected.length > 0) {
          filters += `&payment-methods=${this.contract.filters.paymentMethods.selected}`;
        }

        if (this.contract.filters.marketing.selected.length > 0) {
          filters += `&marketing-types=${this.contract.filters.marketing.selected}`;
        }

        if (this.contract.filters.personTypes.selected.length > 0) {
          filters += `&person-type=${this.contract.filters.personTypes.selected}`;
        }

        if (this.contract.filters.productsCategory.selected.length > 0) {
          filters += `&product-categories=${this.contract.filters.productsCategory.selected}`;
        }

        if (this.contract.filters.flightType.selected.length > 0) {
          filters += `&flight-type=${this.contract.filters.flightType.selected}`;
        }

        if (this.contract.filters.saleType.selected.length > 0) {
          filters += `&sale-type=${this.contract.filters.saleType.selected}`;
        }

        if (this.contract.filters.notUsers.selected.length > 0) {
          filters += `&not-user-id=${this.contract.filters.notUsers.selected}`;
        }

        if (
          this.contract.filters.price.min ||
          this.contract.filters.price.max
        ) {
          let min = this.contract.filters.price.min;
          let max = this.contract.filters.price.max;

          if (min) {
            if (!max) {
              max = "500000";
            }
          }

          if (max) {
            if (!min) {
              min = "0";
            }
          }

          filters += `&price-range=${min}-${max}`;
        }

        if (this.contract.filters.searchTerm) {
          filters += `&s=${this.contract.filters.searchTerm}`;
        }

        filters += `&order=${this.contract.filters.order}&order-by=${this.contract.filters.orderBy}`;

        history.replaceState(null, null, "?" + queryParams.toString());

        this.$http
          .get(
            `/contract-v2/list?page=${this.contract.pagination.page}&per_page=${this.contract.pagination.perPage}${filters}`
          )
          .then(({ data }) => {
            if (!samePage) {
              this.contract.pagination.page = 1;
            }

            this.contract.meta = data.meta;
            this.contract.pagination.total = data.meta.total;
            this.contract.pagination.totalPages = data.meta.total_pages;
            this.contract.list = data.data;

            this.excelFile.footer = [];
            this.excelFile.footer.push(" ");
            this.excelFile.footer.push(`Nº CONTRATOS: ${data.meta.total}`);
            this.excelFile.footer.push(
              `TOTAL GERAL: R$ ${data.meta.total_sold}`
            );
            this.excelFile.footer.push(
              `TOTAL - CARTA DE CRÉDITO: R$ ${data.meta.total_sold_payment_card}`
            );
            this.excelFile.footer.push(
              `TOTAL DE VENDAS: R$ ${data.meta.total_without_payment_card}`
            );
            this.contract.loading = false;
          })
          .catch(({ response }) => {
            response;
            this.contract.list = [];
            this.contract.loading = false;
            this.contract.meta = response.data.meta;
            this.contract.pagination.total = response.data.meta.total;
            this.contract.pagination.totalPages =
              response.data.meta.total_pages;
          });
      }, 100);
    },
  },
};

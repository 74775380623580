<template>
  <section class="pd-20">
    <aPageHeader class="pd-0">
      <template #title>
        <h1>
          <img src="@/assets/images/agreement.png" alt="ico" />
          Contratos
          <span class="reload-btn c-pointer" @click="getContracts()">
            <aTooltip>
              <template slot="title"> Atualizar contratos </template>
              <img
                src="@/assets/images/contract/sync.png"
                alt="reload"
                width="14"
              />
            </aTooltip>
          </span>
        </h1>
      </template>
      <template #extra>
        <downloadExcel
          v-if="$root.isAdmin() || $root.isMilesManager()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="contract.list"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer"
            src="@/assets/images/dashboard/excel.png"
            alt="excel"
          />
        </downloadExcel>

        <aButton type="primary" ghost @click="openCreateCustomer = true">
          <aIcon type="user-add" /> Novo Cliente
        </aButton>

        <aButton
          class="ml-20"
          icon="file-text"
          type="primary"
          ghost
          @click="openCreateContractModal = true"
        >
          Novo Contrato
        </aButton>
      </template>
    </aPageHeader>

    <ContractListValuesPanel :contract="contract" />
    <ContractListFilters :contract="contract" @getContracts="getContracts" />
    <ContractListTable :contract="contract" @getContracts="getContracts" />

    <aDrawer
      title="NOVO CLIENTE"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCustomer"
      @close="openCreateCustomer = false"
    >
      <aForm @submit.prevent="submitCustomer" :form="customerForm">
        <aRow
          class="box box-contractor"
          :gutter="[20, 0]"
          style="margin-top: -20px"
        >
          <CustomerFormComponent
            v-if="openCreateCustomer"
            :customer="customer"
            :customerForm="customerForm"
            :requiredFields="{
              cep: false,
              location_street: false,
              location_number: false,
              location_complement: false,
              location_neighborhood: false,
              location_city: false,
              location_state: false,
              location_country: false,
            }"
          />
          <aCol class="a-center mt-20" :span="24">
            <aButton
              icon="user"
              type="primary"
              :loading="loadingSubmitCustomer"
              html-type="submit"
            >
              Cadastrar cliente
            </aButton>
          </aCol>
        </aRow>
      </aForm>
    </aDrawer>

    <aModal
      class="create-contract-modal"
      v-model="openCreateContractModal"
      :footer="false"
      @cancel="openCreateContractModal = false"
    >
      <template slot="title">
        <aIcon type="file-text" class="mr-5 cprimary" /> NOVO CONTRATO
      </template>
      <NewContractModal
        v-if="openCreateContractModal"
        @closeNewContractModal="openCreateContractModal = false"
      />
    </aModal>
  </section>
</template>

<script>
import { format } from "date-fns";

import contractListMixins from "@/components/contracts/mixins/contractListMixins";
import customerMixins from "@/components/customers/mixins/customerMixins";

import CustomerFormComponent from "@/components/customers/forms/CustomerForm.vue";
import NewContractModal from "@/components/contracts/modals/NewContractModal.vue";
import ContractListValuesPanel from "@/components/contracts/sections/ContractListValuesPanel.vue";
import ContractListFilters from "@/components/contracts/sections/ContractListFilters.vue";
import ContractListTable from "@/components/contracts/sections/ContractListTable.vue";

export default {
  name: "ContractsListV2",
  components: {
    NewContractModal,
    ContractListTable,
    ContractListFilters,
    ContractListValuesPanel,
    CustomerFormComponent,
  },
  mixins: [customerMixins, contractListMixins],
  data() {
    return {
      customerForm: this.$form.createForm(this),
      customer: {},
      openCreateCustomer: false,
      openCreateContractModal: false,
      loadingSubmitCustomer: false,
    };
  },
  beforeMount() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.contract.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("id")) this.contract.filters.id = urlParams.get("id");

    if (urlParams.get("is-ticketed"))
      this.contract.filters.ticketed = urlParams.get("is-ticketed");

    if (urlParams.get("status"))
      this.contract.filters.status.selected = urlParams
        .get("status")
        .split(",");

    if (urlParams.get("user"))
      this.contract.filters.users.selected = urlParams.get("user").split(",");

    if (urlParams.get("id")) this.contract.filters.id = urlParams.get("id");

    if (urlParams.get("open-modal"))
      this.contract.filters.openModal = urlParams.get("open-modal");

    if (urlParams.get("period"))
      this.contract.filters.period.selected = urlParams
        .get("period")
        .split("|");

    // se usuario for tiago n mostrar contratos do saulo
    if (this.$store.state.userData.id == 23)
      this.contract.filters.notUsers.selected = [2];

    if (
      this.$store.state.userData.id == 46 ||
      this.$store.state.userData.id == 45 ||
      this.$store.state.userData.id == 44
    ) {
      this.contract.filters.companyBranches.selected = [
        "1",
        "2",
        "21",
        "26",
        "27",
        "10",
      ];
      this.contract.filters.productsCategory.selected = ["hotel"];
    }

    this.getContracts();
  },
  methods: {
    submitCustomer() {
      this.customerForm.validateFields(async (err, customer) => {
        if (!err) {
          this.loadingSubmitCustomer = true;
          customer.action = "create-customer";
          customer.avatar = "";
          customer.status = "Ativo";
          customer.user_id = this.$store.state.userData.id;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          customer = { ...customer, ...this.customer };

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.openCreateCustomer = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        } else {
          this.$message.warning("Alguns campos não formam preencidos");
        }
      });
    },
  },
};
</script>
